"use strict";

$(document).ready(function () {
  var scroll = $(window).scrollTop();
  var width = $(window).width();

  if (width < 1200) {
    if (scroll <= 150) {
      $(".logo").removeClass("d-none");
      $(".logo").addClass("d-flex");
    } else {
      $(".logo").removeClass("d-flex");
      $(".logo").addClass("d-none");
    }
  } else {
    if (scroll <= 500) {
      $(".logo").removeClass("d-none");
      $(".logo").addClass("d-flex");
      $(".logo-blue").removeClass("d-flex");
      $(".logo-blue").addClass("d-none");
      $(".links-header").find("a").css({
        'color': '#fff'
      });
      $("header").css({
        'background': 'initial',
        'padding': '0 10% 3% 10%',
        'box-shadow': 'initial'
      });
    } else {
      $(".logo").removeClass("d-flex");
      $(".logo").addClass("d-none");
      $(".logo-blue").removeClass("d-none");
      $(".logo-blue").addClass("d-flex");
      $(".links-header").find("a").css({
        'color': '#131938'
      });
      $("header").css({
        'background': '#fff',
        'padding': '1% 10% 1% 10%',
        'box-shadow': '-1px 0px 15px 0px rgba(49, 49, 49, 0.43)'
      });
    }
  }
});
$(function () {
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var width = $(window).width();

    if (width < 1200) {
      if (scroll <= 150) {
        $(".logo").removeClass("d-none");
        $(".logo").addClass("d-flex");
      } else {
        $(".logo").removeClass("d-flex");
        $(".logo").addClass("d-none");
      }
    } else {
      if (scroll <= 500) {
        $(".logo").removeClass("d-none");
        $(".logo").addClass("d-flex");
        $(".logo-blue").removeClass("d-flex");
        $(".logo-blue").addClass("d-none");
        $(".links-header").find("a").css({
          'color': '#fff'
        });
        $("header").css({
          'background': 'initial',
          'padding': '0 10% 3% 10%',
          'box-shadow': 'initial'
        });
      } else {
        $(".logo").removeClass("d-flex");
        $(".logo").addClass("d-none");
        $(".logo-blue").removeClass("d-none");
        $(".logo-blue").addClass("d-flex");
        $(".links-header").find("a").css({
          'color': '#131938'
        });
        $("header").css({
          'background': '#fff',
          'padding': '1% 10% 1% 10%',
          'box-shadow': '-1px 0px 15px 0px rgba(49, 49, 49, 0.43)'
        });
      }
    }
  });
}); // Hamburguer menu

$(function () {
  $(".hamburger").click(function () {
    $(this).toggleClass("is-active");
    var $menu = $(".lateral-menu");
    var $burger = $(".hamburger");

    if ($($menu).css("right") == "-280px") {
      $($menu).css({
        display: 'block'
      });
      $($burger).css({
        position: 'fixed'
      });
      $($menu).animate({
        right: '-5px'
      }, {
        queue: false,
        duration: 200
      });
    } else {
      $($menu).animate({
        right: '-280px'
      }, {
        queue: false,
        duration: 200
      });
      $($menu).css({
        display: 'none'
      });
      $($burger).css({
        position: 'absolute'
      });
    }
  });
}); // Hero carousel

$(document).ready(function () {
  $(".hero-carousel").owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    nav: true,
    checkVisible: false
  });
}); // News carousel

$(document).ready(function () {
  $('.news-carousel').on('initialized.owl.carousel', function (e) {
    $('.owl-item.center').children().addClass('news-checked');
    $('.news-0').addClass('d-block');
    $('.news-0').removeClass('d-none');
    idx = e.item.index;
    $(e.target).find(".owl-item").eq(idx).children().addClass('news-checked');
  });
  $(".news-carousel").owlCarousel({
    nav: true,
    dots: false,
    dotsEach: false,
    lazyLoad: true,
    checkVisible: false,
    autoWidth: false,
    center: false,
    loop: true,
    startPosition: 0,
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 4
      },
      1024: {
        items: 6
      }
    }
  });
  $('.news-carousel').on('change.owl.carousel', function (e) {
    $(e.target).find(".news-checked").removeClass('news-checked');
    newsClass = $(e.target).find(".owl-item").eq(e.item.index).children().attr('data-src');
    $('.' + newsClass).removeClass('d-block');
    $('.' + newsClass).addClass('d-none');
  });
  $('.news-carousel').on('changed.owl.carousel', function (e) {
    $(e.target).find(".owl-item").eq(e.item.index).children().addClass('news-checked');
    newsClass = $(e.target).find(".owl-item").eq(e.item.index).children().attr('data-src');
    $('.' + newsClass).removeClass('d-none');
    $('.' + newsClass).addClass('d-block');
  });
}); // Associates carousel

$(document).ready(function () {
  $(".associates-carousel").owlCarousel({
    items: 1,
    nav: false,
    dots: true,
    checkVisible: true,
    autoplay: true,
    loop: true
  });
}); // Downloads carousel

$(document).ready(function () {
  $(".downloads-carousel").owlCarousel({
    nav: true,
    dots: true,
    lazyLoad: true,
    checkVisible: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1024: {
        items: 3
      }
    }
  });
}); // agenda carousel

$(document).ready(function () {
  $(".agenda-carousel").owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    checkVisible: false,
    autoplay: false,
    loop: false
  });
  $('.agenda-carousel').on('change.owl.carousel', function (e) {
    $('.events-carousel').find(".owl-item").each(function (el) {
      $('.events-carousel').trigger('remove.owl.carousel', el).trigger('refresh.owl.carousel');
    });
  });
  $('.agenda-carousel').on('changed.owl.carousel', function (e) {
    monthClass = $(e.target).find(".owl-item").eq(e.item.index).children().attr('data-src');
    $('.' + monthClass).children().each(function (el) {
      h = $('.' + monthClass).children().eq(el).html();
      $('.events-carousel').trigger('add.owl.carousel', h).trigger('refresh.owl.carousel');
    });
  });
}); // agenda carousel

$(document).ready(function () {
  $(".events-carousel").owlCarousel({
    nav: true,
    dots: true,
    checkVisible: false,
    autoplay: false,
    loop: false,
    responsive: {
      0: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  });
});